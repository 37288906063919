<template>
  <div class="card">
    <div class="card-header">
      <div class="utils__title">
        <strong>Statistiky</strong>
      </div>
    </div>
    <div class="card-body">
      <a-dropdown v-model="visible" :placement="'bottomLeft'" :trigger="['click']">
        <a-button>{{ this.selected.title }} <a-icon type="down" /></a-button>
        <div slot="overlay" class="custom-column-dropdown" :class="{'d-flex': isOpened}" style="align-items: flex-start">
          <a-menu :selectedKeys="[this.selected.key]" class="ant-dropdown-menu ant-dropdown-menu-vertical ant-dropdown-menu-root ant-dropdown-menu-light">
            <a-menu-item class="ant-dropdown-menu-item" v-for="date in dates" @click="changeDates(date.key)" :key="date.key">
              {{ date.title }}
            </a-menu-item>
          </a-menu>
          <div class="p-2" v-if="isOpened">
            <a-range-picker
              showTime
              format="YYYY-MM-DD HH:mm:ss"
              @ok="calendarChange"
              :defaultValue="rangePickerValue"
            />
          </div>
        </div>
      </a-dropdown>
      <div class="row">
        <div class="col-xl-4" v-for="(chartCard, index) in stats" :key="index">
          <cui-chart-card
            :title="chartCard.title"
            :amount="chartCard.amount"
            :chartData="chartCard.chartData"
            :isCurrency="chartCard.isCurrency"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CuiChartCard from '@/components/CleanUIComponents/ChartCard'

export default {
  components: {
    CuiChartCard,
  },
  data: function () {
    return {
      visible: false,
      isOpened: false,
      rangePickerValue: null,
      selected: null,
      dateFrom: null,
      dateTo: null,
      dates: [
        {
          key: 0,
          title: 'Tento měsíc',
          dateFrom: this.$moment().startOf('month'),
          dateTo: this.$moment(),
          picker: false,
        },
        {
          key: 1,
          title: 'Dnes',
          dateFrom: this.$moment().startOf('day'),
          dateTo: this.$moment(),
          picker: false,
        },
        {
          key: 2,
          title: 'Včera',
          dateFrom: this.$moment().subtract(1, 'days').startOf('day'),
          dateTo: this.$moment().subtract(1, 'days').endOf('day'),
          picker: false,
        },
        {
          key: 3,
          title: 'Poslední týden',
          dateFrom: this.$moment().subtract(7, 'days').startOf('day'),
          dateTo: this.$moment(),
          picker: false,
        },
        {
          key: 4,
          title: 'Posledních 30 dní',
          dateFrom: this.$moment().subtract(30, 'days').startOf('day'),
          dateTo: this.$moment(),
          picker: false,
        },
        {
          key: 5,
          title: 'Minulý měsíc',
          dateFrom: this.$moment().subtract(1, 'months').startOf('month'),
          dateTo: this.$moment().subtract(1, 'months').endOf('month'),
          picker: false,
        },
        {
          key: 6,
          title: 'Vlastní rozsah',
          picker: true,
        },
      ],
    }
  },
  computed: {
    stats: function () {
      return this.$store.getters['dashboard/stats']
    },
  },
  methods: {
    changeDates: function (key) {
      if (!this.dates[key].picker) {
        this.visible = false
        this.rangePickerValue = null
        if (!Object.is(this.selected, this.dates[key])) {
          this.initData(this.dates[key].dateFrom, this.dates[key].dateTo)
          localStorage.setItem('__dashboard__selected', JSON.stringify(this.dates[key]))
        }
      }
      this.isOpened = this.dates[key].picker
      this.selected = this.dates[key]
    },
    calendarChange: function (dates) {
      if (dates.length === 2) {
        this.initData(dates[0], dates[1])
        this.selected.dateFrom = dates[0]
        this.selected.dateTo = dates[1]
        localStorage.setItem('__dashboard__selected', JSON.stringify(this.selected))
      }
    },
    initData: function (dateFrom, dateTo) {
      this.$store.dispatch('dashboard/get', {
        date_from: this.$options.filters.formatOutputDate(dateFrom),
        date_to: this.$options.filters.formatOutputDate(dateTo),
      })
    },
  },
  created() {
    if (localStorage.getItem('__dashboard__selected') === null) {
      this.selected = this.dates[0]
      localStorage.setItem('__dashboard__selected', JSON.stringify(this.selected))
    } else {
      const selected = JSON.parse(localStorage.getItem('__dashboard__selected'))
      if (selected.picker) {
        this.selected = selected
        this.isOpened = true
        this.rangePickerValue = [
          this.$moment.utc(new Date(String(this.selected.dateFrom))).local(),
          this.$moment.utc(new Date(String(this.selected.dateTo))).local(),
        ]
      } else {
        this.selected = this.dates[parseInt(selected.key)]
      }
    }
    this.initData(this.selected.dateFrom, this.selected.dateTo)
  },
}
</script>
